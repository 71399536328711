import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { updatePromptPreview, updatePromptPreviewResult } from 'services/redux/slicers/slicers.prompt-editor';
import './pulse-bar.css';
import { TEXT_STYLE } from './prompt-preview';

const REACT_APP_DO_BACKEND_DROPLET_BASE_URL = process.env.REACT_APP_DO_BACKEND_DROPLET_BASE_URL;

export const PromptAnswerPreview = () => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const [isDoneStreaming, setIsDoneStreaming] = useState(null);

	const prompt = useSelector((state) => state.promptEditor.prompt);
	const accessToken = useSelector((state) => state.auth.accessToken);
	const promptPreview = useSelector((state) => state.promptEditor.preview);
	const populatedPrompt = useSelector((state) => state.promptEditor.preview?.populatedPrompt);
	const promptResult = useSelector((state) => state.promptEditor.preview.promptResult);

	const updateContent = useCallback((key, value) => {
		dispatch(updatePromptPreviewResult({ key, value }));
	}, [dispatch])

	useEffect(() => {
		const setLoading = (state) => {
			dispatch(updatePromptPreview({ key: 'generatingPromptResult', value: state }));
		};

		setLoading(true);

		// Stream Request body
		const body = {};
		body.aiPromptId = prompt.id;
		body.gpt_config = prompt.gpt_config;
		body.populatedPrompt = populatedPrompt;
		body.klaviyoWebhookData = promptPreview.payload;

		// For closing the stream
		const abortController = new AbortController();

		// Open a connection for SSE to stream the AI response
		const streamUrl = REACT_APP_DO_BACKEND_DROPLET_BASE_URL + '/stream/prompt_preview';
		fetchEventSource(streamUrl,
			{
				method: 'post',
				body: JSON.stringify(body),
				headers: { 'xano-access-token': accessToken, 'Content-Type': 'application/json' },
				onmessage(event) {
					const { content, completed, key, error: streamError } = JSON.parse(event.data);
					updateContent(key, content?.trim());
					if (streamError) { setError(JSON.stringify(streamError)); setLoading(false); }
					if (completed) { setIsDoneStreaming(val => ({ ...(val || {}), [key]: true })) }
				},
				onopen(res) { if (res.status !== 200) { setError('Error creating connection'); } },
				onclose() { setLoading(false); },
				signal: abortController.signal
			});

		// Cleanup function - clear the prompted result
		return () => {
			abortController.abort();
			setIsDoneStreaming(null);
			dispatch(updatePromptPreview({ key: 'promptResult', value: null }));
		};
	}, []);

	function streamedText(title, resultsKey, spaceTop = false) {
		return (<>
			<Typography sx={{ mt: spaceTop ? '0.5rem' : '0rem' }}>{title}</Typography>

			{/* Always show the 'text' box */}
			{(promptResult?.[resultsKey] || resultsKey === 'text') && (
				<Typography component={'div'} sx={TEXT_STYLE}>
					{promptResult?.[resultsKey]} {!isDoneStreaming?.[resultsKey] && <div className='bar pulse' />}
				</Typography>
			)}
		</>);
	}

	return (
		<Box>
			{!error && (<>
				{prompt.subject_line_template && streamedText('Subject Line', 'subject_line_template')}
				{prompt.preview_text_template && streamedText('Preview Text', 'preview_text_template', true)}
				{streamedText('Main Body', 'text', true)}
			</>)}

			{error != null && (<Typography><b>Error Occured:</b> <br />{error}</Typography>)}
		</Box >
	);
};