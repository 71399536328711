import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import { updatePromptPreview } from "services/redux/slicers/slicers.prompt-editor";
// import HandlebarsExtended from "utils/handlebar-compiler";
import HandlebarsExtended from "handlebarsld";

export const TEXT_STYLE = {
	whiteSpace: 'pre-wrap',
	maxWidth: 'fit-content',
	width: 'max-content',
	borderRadius: 1,
	backgroundColor: '#eee',
	padding: '0.75rem'
};

export const PromptPreview = () => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const preview = useSelector((state) => state.promptEditor.preview);
	const populatedPrompt = useSelector((state) => state.promptEditor.preview?.populatedPrompt);

	// Compile the template (prompt) on page render
	useEffect(() => {
		try {
			// Create a handlebars template
			const compiler = new HandlebarsExtended(prompt);
			const populatedPrompt = compiler.renderTemplate(preview.payload);
			console.log('populatedPrompt', populatedPrompt);
			dispatch(updatePromptPreview({ key: 'populatedPrompt', value: populatedPrompt }));
		} catch (error) {
			console.error('error', error);
			setError(error.message);
		}
	}, [dispatch, preview.payload, prompt]);

	if (error) { return (<Typography><b>Error Occured:</b> <br />{error}</Typography>); }
	else if (!populatedPrompt) { return null; }

	return (
		<Box sx={{ p: 0 }}>
			{populatedPrompt.subject_line_template && (<>
				<Typography><i>Subject Line</i></Typography>
				<Typography sx={TEXT_STYLE}>{populatedPrompt.subject_line_template}</Typography>
			</>)}

			{populatedPrompt.preview_text_template && (<>
				<Typography mt={'0.5rem'}><i>Preview Text</i></Typography>
				<Typography sx={TEXT_STYLE}>{populatedPrompt.preview_text_template}</Typography>
			</>)}

			<Typography mt={'0.5rem'}><i>Main Body</i></Typography>
			<Typography variant="body1" sx={TEXT_STYLE}>{populatedPrompt?.text}</Typography>

			{Array.isArray(populatedPrompt.prompt_rules) && populatedPrompt.prompt_rules.length > 0 && (<>
				<Typography mt={'0.5rem'}><i>Rules</i></Typography>
				<Typography sx={TEXT_STYLE}>
					{preview.populatedPrompt.prompt_rules.map(rule => <div key={rule.order}>{rule.rule_text}</div>)}
				</Typography>
			</>)}
		</Box>
	);
};
