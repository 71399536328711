import { useState } from "react";
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Paper, Button, IconButton, Stepper, Step, StepButton, StepLabel, StepContent } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { clearPromptPreview } from "services/redux/slicers/slicers.prompt-editor";

const MODAL_BOX_STYLE = {
	position: 'absolute',
	left: '50%',
	top: '10%',
	transform: 'translate(-50%, 0%)',
	minWidth: '40vw',
	maxWidth: '90vw',
};

export const ValuesToCopyViewer = ({ steps }) => {
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const [open, setOpen] = useState(false); // State
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux

	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => {
		dispatch(clearPromptPreview());
		setOpen(false);
	};

	function handleStep(step) {
		if (step === steps.length) {
			// last step. close modal and reset active step
			handleModalClose();
			setActiveStep(0);
		} else {
			// not last, go to next
			setActiveStep(step);
		}
	}

	// Only show the preview button if the prompt has been inserted (exists in database) and it doensn't have any uncommited changes
	if (!prompt.id || !steps) { return null; }
	else return (
		<div>
			<Button
				variant="contained"
				endIcon={<ContentCopyIcon />}
				onClick={handleModalOpen}
			>
				Setup
			</Button>

			<Modal open={open} onClose={handleModalClose}>
				<Box sx={MODAL_BOX_STYLE}>
					<IconButton
						size='small'
						aria-label='delete'
						sx={{ color: 'white' }}
						onClick={handleModalClose}
					>
						<CloseIcon />
					</IconButton>

					<Paper sx={{ p: 2, maxHeight: 'calc(90vh - 3rem)', overflow: 'auto' }}>
						<Stepper nonLinear activeStep={activeStep} orientation={'vertical'}>
							{steps.map(({ label, clickable, component }, index) => (
								<Step key={label}>

									{clickable
										&& <StepButton color="inherit" onClick={() => handleStep(index)}>{label}</StepButton>
										|| <StepLabel><i>{label}</i></StepLabel>
									}

									<StepContent>
										{component}
										<div>
											<Button
												variant="contained"
												onClick={() => handleStep(index + 1)}
												sx={{ mt: 1, mr: 1 }}
											// disabled={index === steps.length - 1}
											>
												{index === steps.length - 1 ? 'Finish' : 'Next'}
												{/* Next */}
											</Button>
											<Button
												disabled={index === 0}
												onClick={() => handleStep(index - 1)}
												sx={{ mt: 1, mr: 1 }}
											>
												Back
											</Button>
										</div>
									</StepContent>
								</Step>
							))}
						</Stepper>
					</Paper>
				</Box>
			</Modal>
		</div >
	);
};

ValuesToCopyViewer.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.object)
};
