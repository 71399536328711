import { useEffect, useState } from 'react';
import { words } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Card, Accordion, AccordionSummary, AccordionDetails, Tooltip, Box } from "@mui/material";
import { updatePrompt, } from "services/redux/slicers/slicers.prompt-editor";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PropTypes } from 'prop-types';
import RulesListBuilder from './rules-list-builder';

export const N_MAX_TEXT_INPUT_WORDS = 450;

export function calculateTextInputWords({ key: targetKey, value: targetValue }, prompt) {
	// Gather the rules into on string to count words
	let ruleText = '';
	let rules;
	if (targetKey === 'prompt_rules') {
		rules = targetValue;
	} else {
		rules = prompt?.prompt_rules;
	}

	if (rules) { rules.forEach(rule => ruleText += rule.rule_text + ' '); }

	// Gather the relevant prompt texts, overriding with the target lastly
	let texts = {
		'gpt_config.system_info': prompt?.gpt_config?.system_info || '',
		'text': prompt?.text,
		ruleText,
	};

	if (targetKey !== 'prompt_rules') {
		texts[targetKey] = targetValue;
	}
	// console.log(targetKey, 'texts', texts);
	// Gather all the texts to count words
	let text = '';
	Object.values(texts).forEach(t => text += t + ' ');
	const wordsSum = words(text)?.length;
	return wordsSum;
}

export const TextEditor = ({ children }) => {
	const dispatch = useDispatch();
	const [inputTextSize, setInputTextSize] = useState(null);
	
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const promptValidationErrors = useSelector((state) => state.promptEditor.promptValidationErrors);

	useEffect(() => {
		const wordsSum = calculateTextInputWords({ key: 'text', value: prompt?.text }, prompt);
		setInputTextSize(wordsSum);
	}, [prompt]);

	const textChanged = (e) => {
		const wordsSum = calculateTextInputWords({ key: 'text', value: e.target.value }, prompt);
		if (wordsSum <= N_MAX_TEXT_INPUT_WORDS) {
			dispatch(updatePrompt({ key: 'text', value: e.target.value }));
		}
	};
	
	if (!prompt) { return null; }
	return (
		<Card sx={{ bgcolor: '#2a2e35', minWidth: 300, p: 2 }}>
			<Typography color="white" variant="h5" sx={{ textAlign: 'center', width: 1, mb: 2 }}>
				Prompt
			</Typography>

			<TextField
				value={prompt?.text}
				multiline={true}
				maxRows={20}
				required
				error={Boolean(promptValidationErrors?.text)}
				helperText={promptValidationErrors?.text}
				label="Prompt Text - use {{variable}}"
				onChange={textChanged}
				sx={{
					width: '100%',
					'& .MuiInputBase-input': { color: 'white' }
				}}>
			</TextField>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Tooltip
					title="Max Words for System Info & Prompt Text"
					slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
				>
					<Typography variant='body2' color='white' sx={{ pt: 0.5, textAlign: 'right' }}>
						<i>{inputTextSize} / {N_MAX_TEXT_INPUT_WORDS}</i>
					</Typography>
				</Tooltip>
			</Box>


			<RulesListBuilder />
			<div>
				<Accordion sx={{ color: 'white', bgcolor: '#2a2e35' }} >
					<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
						<Typography>Advanced</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ p: 0 }}>
						{children}
					</AccordionDetails>
				</Accordion>
			</div>
		</Card >
	);
};

TextEditor.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object)
	])
};
