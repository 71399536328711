import { useSelector } from 'react-redux';
import { ClickToCopyTextAndLabel } from '../../account-detail-page/tabs/klaviyo-page/prompt-editor-page/values-to-copy-viewer-components/click-to-copy-text-and-label';

export const EmailUsageStep = () => {
	const prompt = useSelector((state) => state.promptEditor.prompt);

	if (!prompt) { return null; }
	return (
		<div>
			<ClickToCopyTextAndLabel
				label={'Main Body - with fallack value'}
				value={`{{person.main_body_${prompt.id}|default:person.main_body_${prompt.id}_fallback|linebreaksbr}}`}
			/>

			{prompt.subject_line_template && (<><br />
				<ClickToCopyTextAndLabel
					label={'Subject Line'}
					value={`{{person.subject_${prompt.id}}}`}
				/>
			</>)}

			{prompt.preview_text_template && (<><br />
				<ClickToCopyTextAndLabel
					label={'Preview Text'}
					value={`{{person.preview_${prompt.id}|linebreaksbr}}`}
				/>
			</>)}
		</div>
	);
};