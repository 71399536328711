import { Typography, Unstable_Grid2 as Grid } from '@mui/material';

export const KlaviyoAccountDetails = () => {

	return (
		<Grid>
			<Typography>
				Account details
			</Typography>
		</Grid>
	);
};
