import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { convertAccountToAgency, getAccountDetails, putAccountDetails } from "api/account";
import { PERMISSIONS, ACCOUNT_TYPES } from "config/permissions";
import { messageFromSuspectedXanoError } from "api/axios-api";
import { setOpenSuccess } from "services/redux/slicers/slicers.snackbar";
import { clearAccountsState } from "services/redux/slicers/slicers.accounts";
import { logout } from "services/redux/slicers/slicers.auth";

export const SettingsOrganizationPage = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [accountName, setAccountName] = useState('');
	const [error, setError] = useState({ type: null, message: null });
	const user = useSelector((state) => state.auth.user);
	const userAccount = useSelector((state) => state.auth.userAccount);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	useEffect(() => {
		// setLoading(true)
		getAccountDetails(currentlySelectedAccount.id)
			.then(resp => setAccountName(resp.data.name))
			.catch(err => console.error('error getting account details', err))
			.finally(() => setLoading(false))
	}, [currentlySelectedAccount]);

	const accountNameChange = (e) => setAccountName(e.target.value);

	function onSaveClick() {
		setLoading(true);
		putAccountDetails(user.account_id, { name: accountName })
			.catch(err => console.error('error putting account details', err))
			.finally(() => setLoading(false))
	}

	function convertAccToAgency() {
		setLoading(true);
		convertAccountToAgency(user.account_id)
			.then(() => {
				// update the users active account to the new agency and signt them in again
				dispatch(setOpenSuccess({ title: "Converted Successfully" }));
				dispatch(clearAccountsState());
				dispatch(logout());
			})
			.catch(err => {
				setError({ message: messageFromSuspectedXanoError(err), type: 'convert_error' });
				console.error('error converting agency', err);
			})
			.finally(() => setLoading(false));
	}

	return (
		<Box p={2} px={5} position={'relative'}>
			<Typography variant="h6" mb={2}>
				Details
			</Typography>

			<Typography variant="body1">
				Organization Name
			</Typography>

			<Typography variant="body2" color={'#6e6e80'} >
				Human-readable label for your organization, shown in user interfaces
			</Typography>
			<TextField
				label='Name'
				value={accountName}
				onChange={accountNameChange}
			/>
			{/* <br />
			<br />
			<Typography variant="body1">
				Subscription Status:
			</Typography>
			<Typography variant="body2" color={'#6e6e80'} >
				Human-readable label for your organization, shown in user interfaces
			</Typography> */}



			<br />
			<Button onClick={onSaveClick} variant="contained" sx={{ mt: 3 }}>
				Save
			</Button>


			{
				userAccount?.type === ACCOUNT_TYPES.individual
				&& currentlySelectedAccount?.permissions[PERMISSIONS.convertIndividualAccountToAgency] === true
				&& (
					<div>
						<Divider sx={{ my: 5 }} />

						<Button onClick={convertAccToAgency} variant="contained" color="warning">
							Convert to Agency
							<WarningAmberIcon sx={{ marginLeft: '0.5rem' }} />
						</Button>
						{error && error.type === 'convert_error' && (
							<Alert variant="filled" severity="error" sx={{ mt: 1, width: 'fit-content' }}>{error.message}</Alert>
						)}
					</div>
				)}

			<Backdrop
				open={loading}
				sx={{
					color: '#fff',
					borderRadius: '5px',
					position: 'absolute',
					zIndex: (theme) => theme.zIndex.drawer + 1
				}}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box >
	);
};
