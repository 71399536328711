import { createSlice } from '@reduxjs/toolkit';
import { PERMISSIONS } from 'config/permissions';

const initialState = {
	currentlySelected: null,
	// agencyBeingInspected: null,
	all: []
};

export const accountsSlice = createSlice({
	name: 'account',

	initialState,

	reducers: {
		setCurrentlySelectedAccount: (state, action) => {
			// format the permissions before setting the account
			const account = { ...action.payload };
			const formatPermissions = (userPermissionCodes) => {
				const permissions = {};
				const permissionKeys = Object.keys(PERMISSIONS)
				for (let i = 0; i < permissionKeys.length; i++) {
					const permissionName = permissionKeys[i];
					const permissionValue = PERMISSIONS[permissionName];
					if (userPermissionCodes?.includes(PERMISSIONS[permissionName])) {
						permissions[permissionValue] = true;
					}
				}
				// console.log('formatted perms', permissions);
				return permissions;
			};
			account.permissions = formatPermissions(account.permissions);
			state.currentlySelected = account;
		},
		setAllAccounts: (state, action) => {
			state.all = action.payload;
		},
		addNewAccount: (state, action) => {
			if (state.all) state.all.push(action.payload);
			else state.all = [action.payload];
		},
		// setAgencyBeingInspected: (state, action) => {
		// 	state.agencyBeingInspected = action.payload;
		// },
		clearAccountsState: (state) => {
			Object
				.keys(initialState)
				.forEach(key => state[key] = initialState[key]);
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	setCurrentlySelectedAccount,
	setAllAccounts,
	addNewAccount,
	// setAgencyBeingInspected,
	clearAccountsState
} = accountsSlice.actions;

export default accountsSlice.reducer;
