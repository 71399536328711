import React, { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SortableList } from "./sortable-list";
import { useDispatch, useSelector } from "react-redux";
import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";
import { calculateTextInputWords, N_MAX_TEXT_INPUT_WORDS } from "./text-editor";
import { uniqueId } from "lodash";

function Item(props) {
	return (
		<SortableList.Item id={props.rule.id}>
			<TextField
				required
				value={props.rule.rule_text}
				onChange={e => props.updateText(e.target.value)}
				variant={'outlined'}
				error={Boolean(props?.error)}
				helperText={props?.error}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={props.deleteRule} aria-label="toggle password visibility" edge="end">
							<DeleteIcon />
						</IconButton>
					</InputAdornment>
				}
				sx={{
					width: '-webkit-fill-available',
					'& .MuiInputBase-input': { padding: '0.6rem', color: 'white' }
				}}
			></TextField>
			<SortableList.DragHandle />
		</SortableList.Item>
	);
}

Item.propTypes = {
	rule: PropTypes.shape({
		id: PropTypes.string,
		rule_text: PropTypes.string,
	}),
	error: PropTypes.string,
	deleteRule: PropTypes.func,
	updateText: PropTypes.func,
};


export function newPromptRule(rules) {
	const order = rules?.length || 0;
	return { order, rule_text: '', id: uniqueId() };
}

export default function RulesListBuilder() {
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(false);
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const promptValidationErrors = useSelector((state) => state.promptEditor.promptValidationErrors);
	const [ruleError, setRuleError] = useState(null); // { <index>: <error msg> }

	useEffect(() => {
		const rulesWithIds = prompt?.prompt_rules?.map(rule => ({ ...rule, id: uniqueId() }));
		recalculateRuleOrders(rulesWithIds);
	}, []);

	useEffect(() => {
		// Expand accordion if an error was found in the template and its not expanded
		// prompt rule errors are saved by it's index like this: "prompt_rules[0]" as the key
		const ruleErrorValidation = promptValidationErrors && Object.keys(promptValidationErrors).find(key => key.includes('prompt_rules['))
		if (ruleErrorValidation && ruleErrorValidation.match(/\d+/)) {
			const match = ruleErrorValidation.match(/\d+/);
			setRuleError({ [match[0]]: promptValidationErrors[ruleErrorValidation] });
		}
		if (ruleErrorValidation && !expanded) { handleExpansion(); }
		if (ruleError && !ruleErrorValidation) { setRuleError(null); }
	}, [promptValidationErrors, expanded]);

	function updatePromptRules(updatedRules) {
		recalculateRuleOrders(updatedRules);
	}

	function newRule() {
		const rules = prompt?.prompt_rules;
		if (rules.length > 0 && !!rules.find(r => r.rule_text === '')) { return; }
		const newEmptyRule = newPromptRule(rules);
		recalculateRuleOrders([...rules, newEmptyRule]);
	}

	function recalculateRuleOrders(rules) {
		if (!rules || !Array.isArray(rules)) { return; }
		const updatedRules = rules.map((rule, i) => ({ ...rule, order: i }));
		dispatch(updatePrompt({ key: 'prompt_rules', value: updatedRules }));
	}

	function deleteRule(rule) {
		const updatedRules = prompt?.prompt_rules?.filter(r => r.order !== rule.order);
		recalculateRuleOrders(updatedRules);
	}

	function handleExpansion() { setExpanded((prevExpanded) => !prevExpanded); }

	function updateRule(text, rule) {
		let updatedRules = [...(prompt?.prompt_rules || [])];
		for (let i = 0; i < updatedRules.length; i++) {
			if (updatedRules[i]?.order === rule.order) {
				updatedRules[i] = { ...updatedRules[i], rule_text: text };
			}
		}

		// ensure the words dont break the max words limit
		const wordsSum = calculateTextInputWords({ key: 'prompt_rules', value: updatedRules }, prompt);
		if (wordsSum <= N_MAX_TEXT_INPUT_WORDS) {
			dispatch(updatePrompt({ key: 'prompt_rules', value: updatedRules }));
		}
	}

	return (
		<div>
			<Accordion
				expanded={expanded}
				onChange={handleExpansion}
				sx={{ color: 'white', bgcolor: '#2a2e35' }}
				disableGutters={true}
			>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}><Typography>Rules</Typography></AccordionSummary>
				<AccordionDetails sx={{ p: 0 }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 0 }}>
						<Button
							size="small"
							variant="contained"
							onClick={newRule}
							startIcon={<AddIcon />}
						>New Rule</Button>
					</div>

					{prompt?.prompt_rules && (
						<SortableList
							items={prompt?.prompt_rules}
							onChange={updatePromptRules}
							renderItem={rule =>
								<Item
									updateText={text => updateRule(text, rule)}
									deleteRule={() => deleteRule(rule)}
									error={ruleError?.[rule.order]}
									rule={rule}
								/>
							}
						/>
					)}
				</AccordionDetails>
			</Accordion >
		</div>
	);
}
