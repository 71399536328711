import { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { StyledDataGrid } from "assets/styles/components/styled-data-grid";
import { getKlaviyoAccountsForAccount } from "api/account";
import { setOpenError } from "services/redux/slicers/slicers.snackbar";
import { setKlaviyoAccounts } from "services/redux/slicers/slicers.klaviyo-page";
import { messageFromSuspectedXanoError } from "api/axios-api";

const COLUMNS = ({ accountRowClicked }) => [
	{ field: 'id', headerName: 'ID', width: 100, headerAlign: 'center', align: 'center' },
	{
		field: 'name',
		headerName: 'Name',
		width: 300,
		renderCell: (params) => (
			<Link
				component="button" variant="body2"
				onClick={() => accountRowClicked(params.row)}
			>{params.row.name}</Link>
		)
	},
	// { field: 'droplet_validation_key', headerName: 'Leaddrive Api Key', width: 350 },
	// { field: 'private_api_key', headerName: 'Klaviyo API Key', width: 250 },
	{
		field: 'created_at',
		headerName: 'Created',
		type: 'dateTime',
		width: 200,
		valueGetter: ({ value }) => value && new Date(value)
	},
	// {
	// 	field: 'actions',
	// 	type: 'actions',
	// 	getActions: (params) => [
	// 		<GridActionsCellItem icon={<GridDeleteIcon />} onClick={() => { }} label="Delete" key={0} />
	// 	]
	// }
];

export const KlaviyoAccountsTable = () => {
	const navigate = useNavigate(); // Router
	const dispatch = useDispatch(); // Redux
	const klaviyoAccounts = useSelector((state) => state.klaviyo.accounts); // Redux
	const [loading, setLoading] = useState(false); // State
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // State

	useEffect(() => {
		setLoading(true);

		// Get the this currently selected account's prompts
		getKlaviyoAccountsForAccount({ accountId: currentlySelectedAccount.id })
			.then(resp => {
				// console.log('getKlaeviyoAccountsForAccount resp', resp);
				dispatch(setKlaviyoAccounts(resp.data))
			}) // Set the newly retrieved klaviyo accounts
			.catch(error => {
				// Display error in snackbar
				dispatch(setKlaviyoAccounts([]))
				dispatch(setOpenError({ title: "Error Getting Prompts", message: messageFromSuspectedXanoError(error) }));
				console.log('error in getKlaviyoAccountsForAccount', error);
			})
			.finally(() => setLoading(false));
	}, [dispatch, currentlySelectedAccount.id]);

	const accountRowClicked = (klaviyoAccount) => {
		// navigate(`${klaviyoAccount.id}/flows`, { relative: 'path' });
		console.log('moving', klaviyoAccount);
		navigate(`./${klaviyoAccount.id}/klaviyo`, { relative: 'path' });
	};

	return (
		<StyledDataGrid
			loading={loading}
			rows={klaviyoAccounts || []}
			columns={COLUMNS({ accountRowClicked })}
			initialState={{
				sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
				pagination: { paginationModel: { pageSize: 10 } }
			}}
			autoHeight
			pageSizeOptions={[10]}
			disableRowSelectionOnClick
			localeText={{ noRowsLabel: 'No Klaviyo accounts were found' }}
		/>
	);
};