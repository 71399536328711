import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";
import { useEffect, useState } from 'react';
// import { PERMISSIONS } from 'config/permissions';
import { words } from 'lodash';

// If this isn't applied, the top border will be a bright white line, this dulls it abit
const DarkTopBorderAccordion = styled(Accordion)`:before { background-color: #3e3f42 }`;
const N_MAX_INPUT_LENGTH_WORDS = 200;

export const PreviewTextEditor = () => {
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState(false);
	const [inputTextSize, setInputTextSize] = useState(null);
	// const [needToUpgrade, setNeedToUpgrade] = useState(false);
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const promptValidationErrors = useSelector((state) => state.promptEditor.promptValidationErrors);

	useEffect(() => {
		const wordsSum = words(prompt?.preview_text_template).length;
		setInputTextSize(wordsSum);

		// const needsToUpgrade = !(currentlySelectedAccount?.permissions?.[PERMISSIONS.manageSubjectLineAndPreviewText] === true);
		// setNeedToUpgrade(needsToUpgrade);
		// if (needsToUpgrade) { dispatch(updatePrompt({ key: 'preview_text_template', value: '' })); }
	}, [currentlySelectedAccount, prompt?.preview_text_template, dispatch]);

	useEffect(() => {
		// Expand accordion if an error was found in the template and its not expanded
		if (promptValidationErrors?.preview_text_template && !expanded) { handleExpansion(); }
	}, [promptValidationErrors?.preview_text_template, expanded]);

	function handleExpansion() { setExpanded((prevExpanded) => !prevExpanded); }

	function textChanged(e) {
		if (words(e.target.value).length <= N_MAX_INPUT_LENGTH_WORDS) {
			dispatch(updatePrompt({ key: 'preview_text_template', value: e.target.value }));
		}
	}

	if (!prompt) { return null; }
	return (
		<DarkTopBorderAccordion
			expanded={expanded}
			onChange={handleExpansion}
			sx={{ color: 'white', bgcolor: '#2a2e35' }}
			disableGutters={true}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '& .MuiAccordionSummary-root': { backgroundColor: 'grenn', borderTop: '10px', borderTopWidth: '1rem' } }}>
				<Typography>Preview Text</Typography>
			</AccordionSummary>

			<AccordionDetails >
				{/* {needToUpgrade && (
					<Typography variant='body2'>
						<i>This feature is not available on your current tier.</i>
					</Typography>
				)} */}

				<TextField
					value={ prompt?.preview_text_template}
					multiline={true}
					error={Boolean(promptValidationErrors?.preview_text_template)}
					helperText={promptValidationErrors?.preview_text_template}
					label="Template for generating preview text. Reference generated content with: {{main_body}}"
					onChange={textChanged}
					sx={{
						width: '100%',
						'& .Mui-disabled': { color: 'gray' },
						'& .Mui-disabled.root': { color: 'gray' },
						'& .MuiInputBase-input': { color: 'white' },
					}}
				></TextField>

				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Tooltip
						title="Max Words for Preview Text"
						slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
					>
						<Typography variant='body2' color='white' sx={{ pt: 0.5, textAlign: 'right' }}>
							<i>{inputTextSize} / {N_MAX_INPUT_LENGTH_WORDS}</i>
						</Typography>
					</Tooltip>
				</Box>
			</AccordionDetails>
		</DarkTopBorderAccordion>
	);
};

PreviewTextEditor.propTypes = {};