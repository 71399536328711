import { newPromptRule } from 'components/shared-components/prompt-editor/rules-list-builder';
import HandlebarsExtended from 'handlebarsld';

/**
 * Sets up a xano ai_prompt with the default values
 * 
 * @param {Object} param 
 * @param {string} param.klaviyoAccountId - The klaviyo account id to connect the prompt to
 * @returns xano ai_prompt result with default values
 */
export const createEmptyKlaviyoPrompt = ({ klaviyoAccountId }) => ({
	name: '',
	description: '',
	text: '',
	type: 'klaviyo_webhook',
	klaviyo_account_id: klaviyoAccountId,
	keywords: [],
	backup_text_static: '',
	gpt_config: {
		temperature: 1,
		top_p: 1,
		presence_penalty: 0,
		frequency_penalty: 0,
		system_info: '',
		prompt_rules: [newPromptRule([])],
		model: null,
	}
});

/**
 * Determines whether the prompt is ready to be upserted (inserted or updated).
 * Validate if the create/update button should be shown
 *
 * @param {Object} params - The parameters for the upsert decision.
 * @param {'insert' | 'update'} params.actionType - The type of action to perform ('insert' or 'update').
 * @param {Object} params.currentPrompt - The current prompt
 * @param {boolean} params.arePromptsEqual - Indicates whether the current prompt is equal to the original prompt.
 * @param {number} params.minKeywords - The minimum number of keywords required for insertion.
 * @returns {boolean} Returns true if the prompt is ready to be upserted, otherwise false.
 */
export const isKlaviyoPromptReadyToBeUpserted = ({ actionType, currentPrompt, arePromptsEqual }) => {
	if (actionType === 'insert') {
		// If all the below requirements have been met, show the create button
		return Boolean(currentPrompt.text && currentPrompt.name);
	} else if (actionType === 'update') {
		// If any of the values have been changed, show the update button
		return !arePromptsEqual;
	}

	return false;
};

/**
 * Validate the klaviyo prompt. For Klaviyo, we don't have any keywords to assert are in the text, 
 * so we just ensure that the template text is valid* 
 * 
 * @param {Object} prompt 
 * @returns {{ valid: boolean, error: Error | null }} An object with the validation results.
 */
export const validateTemplateForKlaviyoPrompt = (prompt) => {
	let valid = true;
	try {
		// Create a handlebars template
		const compiler = new HandlebarsExtended(prompt);
		compiler.renderTemplate();

		return { valid, error: null };
	} catch (error) {
		console.log('error', error, error.field);
		return { valid: false, error };
	}
};